import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
  },
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full',
  },
  {
    path: 'authentication',
    loadComponent: () => import('./authentication/authentication.page').then((m) => m.AuthenticationPage),
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./dashboard/dashboard.page').then((m) => m.DashboardPage),
  },
  {
    path: 'splash',
    loadComponent: () => import('./splash/splash.page').then((m) => m.SplashPage),
  },
  {
    path: 'reports',
    loadComponent: () => import('./reports/reports.page').then((m) => m.ReportsPage),
  },
  {
    path: 'homepage',
    loadComponent: () => import('./homepage/homepage.page').then((m) => m.HomepagePage),
  },
  {
    path: 'quotation',
    loadComponent: () => import('./quotation/quotation.page').then((m) => m.QuotationPage),
  },
  {
    path: 'error',
    loadComponent: () => import('./error/error.page').then((m) => m.ErrorPage),
  },
  {
    path: 'success',
    loadComponent: () => import('./success/success.page').then((m) => m.SuccessPage),
  },
  {
    path: 'tab1',
    loadComponent: () => import('./tab1/tab1.page').then((m) => m.Tab1Page),
  },
  {
    path: 'tab2',
    loadComponent: () => import('./tab2/tab2.page').then((m) => m.Tab2Page),
  },
  {
    path: 'medical-details',
    loadComponent: () => import('./medical-details/medical-details.page').then((m) => m.MedicalDetailsPage),
  },
  {
    path: 'medical-history',
    loadComponent: () => import('./medical-history/medical-history.page').then((m) => m.MedicalHistoryPage),
  },
  {
    path: 'edit-history',
    loadComponent: () => import('./edit-history/edit-history.page').then((m) => m.EditHistoryPage),
  },
  {
    path: 'ppupload',
    loadComponent: () => import('./ppupload/ppupload.page').then((m) => m.PPUploadPage),
  },
];
